import Head from "next/head";
import { useEffect } from "react";

export default function VideoPlayer({
  data,
  wrapClass = "",
  id,
  iframeId,
  videoId,
  hasOnlyPreview,
  width = 560,
  height = 315,
}) {
  const PlayButton = () => (
    <button
      id="playBtnOne"
      className="play-btn"
      aria-label="Play Video Button"
      data-id={data.id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
        width={80}
        height={80}
      >
        <g fill="none">
          <circle
            cx={40}
            cy={40}
            r={38}
            fill="#000"
            fillOpacity=".25"
            stroke="#FFF"
            strokeWidth={4}
          />
          <path
            fill="#FFF"
            d="M32 55.73c0 1.696 1.978 2.622 3.28 1.536l18.876-15.73c.96-.8.96-2.273 0-3.072L35.28 22.734c-1.302-1.086-3.28-.16-3.28 1.536v31.46z"
          />
        </g>
      </svg>
      <style jsx>{`
        .play-btn {
          z-index: 1;
          width: 100%;
          height: 100%;
        }
      `}</style>
    </button>
  );

  const handleClick = (e) => {
    const playerId = e.currentTarget.dataset.id;
    const iframeId = e.currentTarget.previousElementSibling.id;
    const videoTagContainer = e.currentTarget;
    const playerVars = {
      autoplay: 0, // Auto-play the video on load
      controls: 1, // Show pause/play buttons in player
      showinfo: 0, // Hide the video title
      modestbranding: 1, // Hide the Youtube Logo
      loop: 1, // Run the video in a loop
      fs: 0, // Hide the full screen button
      cc_load_policy: 0, // Hide closed captions
      iv_load_policy: 3, // Hide the Video Annotations
      autohide: 0, // Hide video controls when playing
    };
    window.player = new YT.Player(iframeId, {
      videoId: playerId, // YouTube Video ID
      width: width, // Player width (in px)
      height: height, // Player height (in px)
      playerVars,
      events: {
        /* global YT */
        onReady: (e) => {
          e.target.mute();
          e.target.playVideo();
          videoTagContainer
            .querySelector("#playBtnOne")
            .classList.add("d-none");
        },
      },
    });
  };
  const iframeScript = () => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = "defer";
    script.src = "https://www.youtube.com/iframe_api";
    document.head.appendChild(script);
  };
  const youtubeScript = () => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.defer = "defer";
    script.src =
      "https://www.youtube.com/s/player/4eb6b35d/www-widgetapi.vflset/www-widgetapi.js";
    document.head.appendChild(script);
  };
  useEffect(() => {
    iframeScript();
    youtubeScript();
  });

  return (
    <>
      <Head>
        <link
          rel="preload"
          as="script"
          href="https://www.youtube.com/iframe_api"
        />
      </Head>
      <div className={`video-highlight ${wrapClass}`}>
        <div id={iframeId}></div>
        <a
          className="video-timg"
          id={videoId}
          data-id={id}
          onClick={handleClick}
        >
          <video
            autoPlay
            muted
            loop
            poster={data.poster}
            preload="none"
            data-id={data.id}
            className="highlight-video md:w-100"
            playsInline
          >
            <source src={data.src} type="video/mp4" />
          </video>
          <PlayButton />
        </a>
      </div>
    </>
  );
}
