import React from "react";

type ResponsiveImageProps = {
  dpi?: string;
  mobile?: string;
  tablet?: string;
  imgClass?: string;
  wrapClass?: string;
  hasMobileImg?: boolean;
  hasTabletImg?: boolean;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const ResponsiveImage = ({
  dpi = "-2x",
  mobile = "mobile",
  tablet = "tablet",
  imgClass,
  wrapClass,
  src,
  alt,
  hasMobileImg = true,
  hasTabletImg = false,
  ...props
}: ResponsiveImageProps) => {
  return (
    <>
      <picture className={`${wrapClass ? wrapClass : ""}`}>
        {hasMobileImg && (
          <>
            <source
              srcSet={`${src}-${mobile}.webp, ${src}-${mobile}${dpi}.webp 2x`}
              type="image/webp"
              media="(max-width: 767px)"
            />
            <source
              srcSet={`${src}-${mobile}.png, ${src}-${mobile}${dpi}.png 2x`}
              type="image/png"
              media="(max-width: 767px)"
            />
          </>
        )}

        {hasTabletImg && (
          <>
            <source
              srcSet={`${src}-${tablet}.webp, ${src}-${tablet}${dpi}.webp 2x`}
              type="image/webp"
              media="(max-width: 1023px)"
            />
            <source
              srcSet={`${src}-${tablet}.png, ${src}-${tablet}${dpi}.png 2x`}
              type="image/png"
              media="(max-width: 1023px)"
            />
          </>
        )}

        <source
          srcSet={`${src}.webp, ${src}${dpi}.webp 2x`}
          type="image/webp"
        />
        <source srcSet={`${src}.png, ${src}${dpi}.png 2x`} type="image/png" />
        <img
          loading="lazy"
          src={`${src}.png`}
          alt={alt}
          className={imgClass ? imgClass : ""}
          {...props}
        />
      </picture>
    </>
  );
};

export default ResponsiveImage;

// Usage
{
  /* <ResponsiveImage
  src={data.image.src}
  alt={data.image.alt}
  dpi={data.image.prefixes.dpi}
  mobile={data.image.prefixes.mobile}
  tablet={data.image.prefixes.tablet} or leave empty if "tablet" is the prefix
  imgClass={`class-name`}
  wrapClass={`class-name`}
  hasMobileImg={true/false}
  hasTabletImg={true/false}
/>; */
}
