import { useEffect } from "react";
import useDetectDevice from "../../../util/detectDevice";
import { viewport } from "@styles/cssinjs/helpers/variables";

//describing the shape of the data prop passed to the component
type phoneProps = {
  icon: string;
  text: string;
  rating: string;
};
type RatingData = {
  ios: phoneProps;
  android: phoneProps;
};
type RatingsUIProps = {
  data: RatingData;
  order?: string;
  className?: string;
};

const RatingsUI = ({
  data,
  order = "initial",
  className = "",
}: RatingsUIProps) => {
  const userDevice = useDetectDevice();
  useEffect(() => {
    if (userDevice !== "") {
      document.body.classList.add(`device--${userDevice}`);
    }
  }, [userDevice]);

  return (
    <div
      className={`app-review-wrap is-adaptive lg:d-fx fs-0 md:w-100 lg:maxw-lg ${className}`}
    >
      <div
        className="app-review lg:mr-5"
        data-devicetype="iphone"
        data-review-type="app-store"
      >
        <figure dangerouslySetInnerHTML={{ __html: data.ios.icon }} />
        <div className="md:text-left">
          <p>{data.ios.text}</p>
          <i dangerouslySetInnerHTML={{ __html: data.ios.rating }} />
        </div>
      </div>
      <div
        className="app-review"
        data-devicetype="android"
        data-review-type="play-store"
      >
        <figure dangerouslySetInnerHTML={{ __html: data.android.icon }} />
        <div className="md:text-left">
          <p>{data.android.text}</p>
          <i dangerouslySetInnerHTML={{ __html: data.android.rating }} />
        </div>
      </div>
      <style jsx>{`
        @media (max-width: ${viewport.tablet.sm}) {
          .app-review-wrap {
            order: ${order};
          }
        }
      `}</style>
    </div>
  );
};

export default RatingsUI;
