import { useState, useEffect } from "react";

const useDetectDevice = () => {
  const [userDeviceInfo, setUserDeviceInfo] = useState("");

  useEffect(() => {
    const userAgent =
      typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
    if (userAgent.includes("Android")) {
      setUserDeviceInfo("android");
    }
    if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
      setUserDeviceInfo("ios");
    }
  }, []);

  return userDeviceInfo;
};

export default useDetectDevice;
