import ResponsiveImage from "../../atoms/image/ResponsiveImage";
import RatingsUI from "@components/molecules/common/RatingsUI";
import VideoPlayer from "../../organisms/common/VideoPlayer";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "../../../styles/cssinjs/helpers/variables.js";
export default function CutoutFeaturedImage({
  data,
  className,
  children,
  imgClassName,
}) {
  const imgAttrs = {};
  const appMode = process.env.APP_MODE;
  const link = data?.cta?.href?.[appMode] || data?.cta?.href;
  const TheImage = () => {
    if (data.image.hasOwnProperty("dimensions")) {
      imgAttrs.width = data.image.dimensions[0];
      imgAttrs.height = data.image.dimensions[1];
    }
    return (
      <ResponsiveImage
        src={data.image.src}
        alt={data.image.alt}
        mobile={data.image.prefixes.mobile}
        dpi={data.image.prefixes.dpi}
        imgClass={imgClassName ? imgClassName : "imgholder-quinary"}
        {...imgAttrs}
      />
    );
  };
  const TheVideo = () => {
    return (
      <VideoPlayer
        data={data.video}
        id={data.video.id}
        iframeId="videoUtubeOne"
        videoTagId="videoTimgOne"
        wrapClass="md:mb-5 md:ml-2.5 md:mr-2.5"
      />
    );
  };
  return (
    <section
      className={`use-setmore-wrap bg-light pos-rel ${
        className ? className : ``
      }`}
    >
      <div className="mx-auto lg:d-fx">
        <figure>
          {data.hasOwnProperty("image") && <TheImage />}
          {data.hasOwnProperty("video") && <TheVideo />}
          {data.hasOwnProperty("ratings") &&
            data.ratings.hasOwnProperty("hasRatingUnderImage") && (
              <RatingsUI
                data={data.ratings}
                className="lg:px-6 lg:py-4 lg:bg-white br-8 lg:ml-12.5 lg:mt-5 md:mb-3"
              />
            )}
        </figure>{" "}
        <article
          className={`lg:maxw-md md:ml-2.5 md:mr-2.5 ${
            data.hasOwnProperty("ratings") ? "md:fx md:fx--c" : ""
          }`}
        >
          {data.hasOwnProperty("title") && (
            <h2
              className="text-lg lg:mt-5 md:mt-4 md:mb-2"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
          )}
          {Array.isArray(data.desc) ? (
            data.desc.map((item, index) => {
              return (
                <p
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                  className="mt-2 md:mt-1.5"
                />
              );
            })
          ) : (
            <p dangerouslySetInnerHTML={{ __html: data.desc }} />
          )}
          {data.hasOwnProperty("cta") && (
            <a
              href={link}
              className={`btn-${
                data.cta.buttonType ? data.cta.buttonType : "primary"
              } md:w-100`}
            >
              <span>{data.cta.text}</span>
            </a>
          )}
          {data.hasOwnProperty("ratings") &&
            !data.ratings.hasOwnProperty("hasRatingUnderImage") && (
              <RatingsUI
                data={data.ratings}
                order="-1"
                className="lg:mt-5 md:mb-3"
              />
            )}
        </article>
      </div>
      <style jsx>{`
        @media (max-width: ${viewport.desktop.sm}) {
          .use-setmore-wrap :global(img) {
            width: 100%;
            height: auto;
          }
        }
        @media (max-width: ${viewport.tablet.sm}) {
          .use-setmore-wrap :global(figure) {
            text-align: center;
          }
          .use-setmore-wrap :global(img) {
            width: 90% !important;
            height: 100% !important;
          }
          .use-setmore-wrap :global(picture) :global(img) {
            margin-top: -50px;
          }
        }
      `}</style>
    </section>
  );
}
